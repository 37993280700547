<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link :to="{path:'/dashboard'}" class="brand-logo">
  
      <h2 class="brand-text text-primary ml-1">
        <img src="/img/eqcoop.svg" width="100px"/>
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <img src="/img/404_nuevo.svg" style="width: 350px;" />
        <h2 class="mb-1" style="font-weight: bold;margin-top:25px">
          Página no encontrada 🕵🏻‍♀️
        </h2>
        <p class="mb-2" style="font-size:16px; ">
          Oops! 😖 La página que buscabas no se encuentra disponible.
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{path:'/dashboard'}"
        >
          Ir al dashboard
        </b-button>

      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {

    }
  },
  computed: {

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
